<template>
  <div>
    <div v-if="patientsLoading">
      <p class="taskListLoading ufmt-lg text-ibm-plex-sans dark-text">
        Loading <font-awesome-icon icon="spinner" class="fa-lg" spin />
      </p>
    </div>
    <v-slide-y-transition v-else>
      <div class="detailsHolder ufpa-sm">
        <div>
          <p class="taskListInfo dark-text">{{ $t(`${selectedTaskListItem.description}`) }}</p>
          <div class="actionContainer">
            <div
              v-if="!columnLengthIsZero"
              class="left-container ufmt-sm ufmb-sm"
            >
              <Select
                name="columnSelector"
                label="Select columns to display"
                :is-multi-select="true"
                :allOptions="allColumnOptions"
                :placeholderText="'Select Columns'"
                v-model="selectedColumns"
                @change="columnSelected($event)"
              />
            </div>
            <PageTurner
              @changePageEvent="changePage"
              :pageSize="pageSize"
              :totalItems="totalPatients"
              :currentPage="selectedPage"
              :pageTurnerSize="5"
            ></PageTurner>
          </div>
        </div>
        <div class="patientsTableContainer">
        <Table
            :columns="tableColumns"
            :data="tableData"
            :pageSize="pageSize"
            :searchable="true"
          ></Table>
        </div>
      </div>
    </v-slide-y-transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Select from '@/app/shared/components/Select.vue'
import Table from '@/app/shared/components/Table.vue'
import PageTurner from '@/app/shared/components/PageTurner.vue'
import EventBus from '@/app/shared/event-bus.js'

export default {
  name: 'TasklistTabsSection',
  components: {
    Select,
    Table,
    PageTurner
  },
  data: function () {
    return {
      allColumnOptions: [],
      selectedColumns: [],
      selectedPage: 1,
      sortBy: 'Id',
      sortDesc: false,
      tableColumns: [],
      tableData: []
    }
  },
  watch: {
    allcolumns (newValue, oldValue) {
      // eslint-disable-next-line eqeqeq
      if (newValue != oldValue) {
        this.allColumnOptions = newValue.map((item) => {
          return { Key: item.id, Value: item.value }
        })
        if (newValue.length === 0) {
          this.selectedColumns = []
          this.tableColumns = []
        } else {
          this.selectedColumns = newValue.map((item) => {
            return { Key: item.id, Value: item.value }
          })
        }
      }
    },
    tableColumns (newColumns, oldColumns) {
      // eslint-disable-next-line eqeqeq
      if (newColumns != oldColumns) {
        if (newColumns.length === 0) {
          this.updateTableData([])
        } else {
          this.updateTableData(this.patientsData.patients)
        }
      }
    },
    '$route.name': {
      handler: async function (name) {
        this.onTabChange()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    columnLengthIsZero () {
      return this.allcolumns.length === 0
    },
    numberPages () {
      return Math.ceil(this.totalPatients / this.pageSize)
    },

    ...mapState('Tasklist', [
      'taskListsDetails',
      'activeItem',
      'patientsLoading',
      'allcolumns',
      'patientsData',
      'pageSize',
      'totalPatients',
      'inputData',
      'taskListsDetailsLoading',
      'taskListDescInputDataMap',
      'selectedTaskListItem',
      'taskListItemMappedByTasklistDesc',
      'columnDetailsMappedByValue'
    ])
  },
  methods: {
    ...mapActions('Tasklist', [
      'loadTaskListFunction',
      'loadTaskListDetails',
      'setInputData',
      'setSelectedTasklistItem'
    ]),
    itemClicked (column, item) {
      if (column === 'Id' || column === 'Name') {
        window.open('/dashboard/patient/' + item.Id, '_blank')
      }
    },
    columnSelected (e) {
      if (this.selectedColumns.length === 0) {
        this.tableColumns = []
      } else {
        this.updateTableColumns()
      }
    },
    updateTableColumns () {
      const newTableCloumns = []
      console.log(this.selectedColumns)
      this.selectedColumns.forEach((item) => {
        if (
          Object.prototype.hasOwnProperty.call(
            this.columnDetailsMappedByValue,
            item.Key
          )
        ) {
          const clmOjb = {
            name: this.columnDetailsMappedByValue[item.Key].id,
            key: this.columnDetailsMappedByValue[item.Key].id,
            sortable: this.columnDetailsMappedByValue[item.Key].sortable,
            clickable: true
          }
          newTableCloumns.push(clmOjb)
        }
      })
      this.tableColumns = newTableCloumns
    },
    async changePage (number) {
      if (!this.patientsLoading) {
        this.selectedPage = number
        await this.loadTaskList()
        this.updateTableColumns()
        this.updateTableData()
      }
    },
    updateTableData (data) {
      this.tableData = data
    },
    async loadTaskList () {
      console.log(this.selectedPage - 1)
      await this.loadTaskListFunction({
        input: this.inputData,
        page: this.selectedPage - 1,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      })
    },
    async loadDetails (pageNumber) {
      await this.loadTaskListFunction({
        input: this.inputData,
        page: 0,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc
      })
      this.updateTableColumns()
    },
    async onTabChange () {
      this.selectedPage = 1
      const desc = this.$route.name
      await this.setInputData(this.taskListDescInputDataMap[desc])
      await this.setSelectedTasklistItem(
        this.taskListItemMappedByTasklistDesc[desc]
      )
      await this.loadDetails(0)
    }
  },
  async mounted () {
    await this.loadDetails(0)
    EventBus.$on('clickTableEvent', this.itemClicked)
  }
}
</script>

<style lang="scss">
.left-container {
  display: flex;
  align-items: center;
  width: fit-content;
}
.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
<style lang="scss" scoped>
#search-card {
  font-family: "IBM Plex Sans" !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0.005em !important;
  padding: 2rem !important;
}
</style>
