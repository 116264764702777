import TasklistOverview from '../app/Pages/dashboard/tasklist/TasklistOverview.vue'
import TassklistDetails from '../app/Pages/dashboard/tasklist/TassklistDetails.vue'
import TasklistTabsSection from '../app/Pages/dashboard/tasklist/componenets/TasklistTabsSection.vue'

export const defaultEarliestEnrollment = new Date('2017-05-10')

export const defaultPageSize = 20

export const RouteConstants = {
  TasklistOverview: {
    path: 'tasklists'
  },
  TaskListDetails: {
    path: 'tasklistdetails',
    children: {
      tasklist_desc_end_date_passed: {
        path: 'tasklist_desc_end_date_passed'
      }
    }
  }
}
export const tasklistChildren = [
  {
    path: '/',
    component: TasklistOverview
  },
  {
    path: RouteConstants.TaskListDetails.path,
    name: RouteConstants.TaskListDetails.path,
    component: TassklistDetails,
    children: [
      {
        path: 'tasklist_desc_end_date_passed',
        name: 'tasklist_desc_end_date_passed',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_high_attention',
        name: 'tasklist_desc_high_attention',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_mis_1',
        name: 'tasklist_desc_mis_1',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_lost_to_follow_up',
        name: 'tasklist_desc_lost_to_follow_up',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_lfu',
        name: 'tasklist_desc_lfu',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_follow_up',
        name: 'tasklist_desc_follow_up',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_dose_not_reported_today',
        name: 'tasklist_desc_dose_not_reported_today',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_mis_2',
        name: 'tasklist_desc_mis_2',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_missed_ivr_checkin',
        name: 'tasklist_desc_missed_ivr_checkin',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_missed_last_three_doses',
        name: 'tasklist_desc_missed_last_three_doses',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_missed_last_two_doses',
        name: 'tasklist_desc_missed_last_two_doses',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_missed_yesterday_dose',
        name: 'tasklist_desc_missed_yesterday_dose',
        component: TasklistTabsSection
      },
      {
        path: 'tasklist_desc_negative_ivr_checkin',
        name: 'tasklist_desc_negative_ivr_checkin',
        component: TasklistTabsSection
      },

      {
        path: 'tasklist_desc_refill_due',
        name: 'tasklist_desc_refill_due',
        component: TasklistTabsSection
      },

      {
        path: 'tasklist_desc_sputum_test_due',
        name: 'tasklist_desc_sputum_test_due',
        component: TasklistTabsSection
      },

      {
        path: 'tasklist_desc_treatment_outcome_due',
        name: 'tasklist_desc_treatment_outcome_due',
        component: TasklistTabsSection
      },
      {
        path: 'patients_who_have_missed_doses_1_to_3',
        name: 'patients_who_have_missed_doses_1_to_3',
        component: TasklistTabsSection
      },
      {
        path: 'patients_who_have_missed_doses_4_to_7',
        name: 'patients_who_have_missed_doses_4_to_7',
        component: TasklistTabsSection
      },
      {
        path: 'patients_who_have_missed_doses_8_to_14',
        name: 'patients_who_have_missed_doses_8_to_14',
        component: TasklistTabsSection
      },
      {
        path: 'patients_who_have_missed_doses_15_plus',
        name: 'patients_who_have_missed_doses_15_plus',
        component: TasklistTabsSection
      },
      {
        path: 'patients_whose_treatment_outcome_is_due',
        name: 'patients_whose_treatment_outcome_is_due',
        component: TasklistTabsSection
      },
      {
        path: 'patients_whose_bank_details_are_missing',
        name: 'patients_whose_bank_details_are_missing',
        component: TasklistTabsSection
      },
      {
        path: 'patients_whose_merm_devices_are_running_low_on_battery',
        name: 'patients_whose_merm_devices_are_running_low_on_battery',
        component: TasklistTabsSection
      },
      {
        path: 'patients_with_refill_due',
        name: 'patients_with_refill_due',
        component: TasklistTabsSection
      },
      {
        path: 'patients_who_have_registered_through_screening_tool_but_have_not_visited_the_facility',
        name: 'patients_who_have_registered_through_screening_tool_but_have_not_visited_the_facility',
        component: TasklistTabsSection
      },
      {
        path: 'patients_who_have_not_reported_a_manual_or_digital_dose_today',
        name: 'patients_who_have_not_reported_a_manual_or_digital_dose_today',
        component: TasklistTabsSection
      },
      {

        path: 'tasklist_desc_individual_died',
        name: 'tasklist_desc_individual_died',
        component: TasklistTabsSection

      },
      {
        path: 'tasklist_sputum_test_due',
        name: 'tasklist_sputum_test_due',
        component: TasklistTabsSection
      }
    ]
  }
]

export const adherenceCodeOrder = {
  ENROLLMENT: 1,
  NO_INFO: 2,
  RECEIVED: 3,
  UNVALIDATED: 4,
  TFN_REPEAT_RECEIVED: 5,
  MANUAL: 6,
  PATIENT_MANUAL: 7,
  MISSED: 8,
  PATIENT_MISSED: 9,
  RECEIVED_UNSCHEDULED: 10,
  RECEIVED_UNSURE: 11,
  TFN_REPEAT_RECEIVED_UNSURE: 12
}

export const weeklyDosageEnabledDeployments = [
  'TPTUGA'
]

export const weeklyDosageEnabledAdherenceTechs = [
  '99dots'
]
