import { isNullOrUndefined, isNotNullNorEmpty, renameKeys } from '../../../utils/utils.js'
import { defaultToast, ToastType } from '../../../utils/toastUtils'
import { RegistryServerClient } from '../store/RegistryApi'
export const SelectMixin = {
  data (self) {
    return {
      localValue: ((self.value) ? self.value : (this.isMultiSelect ? [] : {})),
      dropdownOpen: false,
      selectAllOptions: false,
      localSearchValue: '',
      filteredOptions: this.allOptions,
      maxLength: 20,
      multiselectmaxLength: 15,
      allOptionsOriginal: this.allOptions
    }
  },
  name: 'Select',
  mounted () {
    if (this.remoteUrl !== null && this.remoteUrl !== '' && this.remoteUrl !== undefined) {
      this.makeRemoteCall()
    }
    this.localValue = ((this.value) ? this.value : (this.isMultiSelect ? [] : ''))
    this.updateSelectAllOptions()

    const self = this
    document.addEventListener('click', function (event) {
      event.stopPropagation()
      if ((self.name !== event.target.name)) {
        self.closeDropdown()
      }
    })
  },
  props: {
    value: {
      type: [Object, Array, String, Number],
      default: null
    },
    name: {
      type: String
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    allOptions: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ''
    },
    hasError: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholderText: {
      type: String,
      default: 'Default option'
    },
    isMultiSelect: {
      type: Boolean,
      default: false
    },
    remoteUrl: {
      type: String,
      default: null
    },
    isEditing: {
      type: Boolean,
      default: true
    },
    selectedText: {
      type: String,
      default: 'options'
    },
    orientation: {
      type: String,
      default: 'h'
    }
  },
  watch: {
    localSearchValue () {
      this.filteredOptions = this.allOptionsOriginal
        .filter(o => o.Value.toLowerCase().includes(this.localSearchValue.toLowerCase()))
    },
    value (val) {
      this.localValue = val
    },
    localValue (value) {
      this.$emit('input', value)
    },
    allOptions () {
      this.filteredOptions = this.allOptions
      this.allOptionsOriginal = this.allOptions && this.allOptions.length > 0 ? this.allOptions : this.allOptionsOriginal
    },

    filteredOptionsToShow () {
      this.updateSelectAllOptions()
    }
  },
  computed: {
    sidebarSelect () {
      if (this.$parent && this.$parent.$parent) {
        return this.$parent.$parent.$data.name === 'Sidebar'
      }
      return false
    },
    filteredOptionsToShow () {
      const newKeys = { key: 'Key', value: 'Value' }
      if (isNullOrUndefined(this.allOptionsOriginal)) {
        return []
      }
      this.allOptionsOriginal.map((item, key) => {
        this.allOptionsOriginal[key] = renameKeys(item, newKeys)
      }
      )
      if (!isNullOrUndefined(this.allOptionsOriginal) && !isNullOrUndefined(this.localSearchValue)) {
        return this.allOptionsOriginal
          .filter(o => !isNullOrUndefined(o.Value) && o.Value.toLowerCase().includes(this.localSearchValue.toLowerCase()))
      } else {
        return this.allOptionsOriginal
      }
    },
    displayValue () {
      let value = !this.value ? '' : this.value
      if (!this.localValue || Object.keys(this.localValue).length === 0) {
        return value
      }

      if (this.isMultiSelect) {
        let currentLength = 0
        let additionalItems = 0
        const selectedItems = []

        for (const option of this.localValue) {
          // eslint-disable-next-line
          const filteredOptions = this.allOptionsOriginal.filter(o => o.Key == option.Key)
          if (filteredOptions.length === 0) {
            continue
          }
          let optLength = this.multiselectmaxLength
          if (this.localValue.length === 1) {
            optLength = this.maxLength + 8
          }
          const optionText = filteredOptions[0].Value
          if ((currentLength + optionText.length) < this.multiselectmaxLength) {
            currentLength += optionText.length
            selectedItems.push(this.$t(optionText))
          } else {
            let opt = optionText
            if (selectedItems.length === 0) {
              if (optionText.length > optLength) {
                opt = optionText.substring(0, optLength - 4) + '...'
              }
              selectedItems.push(this.$t(opt))
              currentLength += opt.length
            } else {
              additionalItems++
            }
          }
        }
        value = selectedItems.join(',')
        if (additionalItems > 0) {
          value += ' + ' + this.$t(additionalItems) + ' more'
        }
      } else {
        const optionText = this.computeDisplayValue
        if (optionText.length > this.maxLength) {
          value = optionText.substring(0, this.maxLength) + '...'
        } else {
          value = optionText
        }
      }
      return value
    },
    computeDisplayValue () {
      let optionText = this.calculateLocalValue
      if (this.allOptions) {
        var options = this.allOptions.filter(o => o.Key + '' === optionText + '')
        if (options.length > 0) {
          optionText = options[0].Value
        }
      }
      return optionText
    },
    calculateLocalValue () {
      let value = ''
      if (isNotNullNorEmpty(this.localValue)) {
        value = this.localValue.Value ? this.localValue.Value : this.localValue
      }
      return value
    },
    displayError () {
      // const hasValue = Object.keys(this.localValue).length > 0
      return this.hasError
      // && !hasValue
    }
  },
  methods: {
    isShowLabel () {
      if (isNotNullNorEmpty(this.label)) {
        return true
      } else {
        return false
      }
    },
    getInputClass () {
      return {
        'ew-input': true,
        'sidebar-select': this.sidebarSelect,
        select: true,
        error: this.hasError
      }
    },

    getDropdownClass () {
      return {
        'dropdown-menu': true,
        'sidebar-select-dropdown': this.sidebarSelect,
        show: this.dropdownOpen
      }
    },

    toggleDropdown () {
      if (this.dropdownOpen) {
        this.closeDropdown()
      } else {
        this.openDropdown()
      }
    },

    openDropdown () {
      this.dropdownOpen = true
    },

    closeDropdown () {
      this.dropdownOpen = false
    },

    handleSelect (event, option) {
      event.stopPropagation()
      const selectedValue = this.fetchedSelectedValue(option)

      if (this.isMultiSelect) {
        if (this.localValue && this.localValue.map((item) => item.Key).includes(selectedValue.Key)) {
          this.localValue.splice(this.localValue.map((item) => item.Key).indexOf(selectedValue.Key), 1)
        } else {
          this.localValue.push(selectedValue)
        }
        this.selectAllOptions =
          this.localValue.length === this.filteredOptionsToShow.length
      } else {
        this.localValue = selectedValue
        this.closeDropdown()
      }
      this.localSearchValue = ''
      this.onChange()
    },

    handleSelectAll () {
      if (!this.selectAllOptions) {
        this.selectAllOptions = true
        this.localValue = this.filteredOptionsToShow.map(option => this.fetchedSelectedValue(option))
        this.onChange()
      } else {
        this.handleClearSelection()
      }
    },

    handleClearSelection () {
      this.selectAllOptions = false
      this.localValue = []
      this.onChange()
    },

    updateSelectAllOptions () {
      this.selectAllOptions = this.isMultiSelect
        ? this.localValue.length === this.filteredOptionsToShow.length
        : false
    },

    fetchedSelectedValue (option) {
      return {
        Data: {
          Key: option.Key,
          Value: option.Value
        },
        Key: option.Key,
        Value: option.Value
      }
    },

    isOptionSelected (value) {
      if (!this.localValue) {
        return false
      }
      return this.localValue.map((item) => item.Key).includes(value)
    },

    async makeRemoteCall () {
      if (this.remoteUrl !== null && this.remoteUrl !== undefined) {
        const url = this.remoteUrl
        try {
          const response = await RegistryServerClient.get(url)
          // Currently works only with response type object
          // TODO: Make it usable for response type array as well
          this.allOptionsOriginal = response.data ? response.data : response.Data
          // this.localValue = this.isMultiSelect ? [] : ''
          this.localSearchValue = ''
        } catch (err) {
          defaultToast(ToastType.Error, 'Sorry, could not load options for select dropdown at the moment')
        }
      }
    }
  }
}
