<template>
<div class="ufma-sm">
    <h1 class="page-header ufpb-xs ufmb-lg text-ibm-plex-sans dark-text">{{$t('_task_list')}}</h1>
    <p class="taskListLoading text-ibm-plex-sans" v-if="taskListsDetailsLoading"> {{$t('Loading')}}<font-awesome-icon icon="spinner" class="fa-lg" spin />
    <div v-else>
      <TasklistItems/>
    </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TasklistItems from './componenets/TasklistItems.vue'

export default {
  name: 'TasklistOverview',
  metaInfo: {
    title: 'Tasklist Overview'
  },
  components: {
    TasklistItems
  },
  computed: {
    ...mapState('Tasklist', ['taskListsDetailsLoading'])
  },
  methods: {
    ...mapActions('Tasklist', [
      'loadTaskListDetails'
    ])
  },
  async mounted () {
    this.loadTaskListDetails()
  }
}
</script>

<style lang="scss" scoped>
.taskListLoading {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}

.page-header {
    border-bottom: 1px solid $tasklistContainerbg;
}
</style>
