<template>
  <div :class="['ew-field-group', 'ew-field-group-horizontal', {'ew-field-group-vertical': orientation === 'v'}]">
    <label v-if="isShowLabel()" :class="[
        'ew-field-label', 'text-align-right', 'flex1', 'ew-label-weight',
        {'text-align-left': orientation === 'v'},
        {'error': hasError}]">
      {{ $t(label) }}
    </label>
    <div v-if="isEditing" class="flex1">
      <input
        :name=name
        :class=getInputClass()
        :placeholder=placeholderText
        :label=label
        :disabled="isDisabled"
        readonly
        :value="$t(displayValue)"
        v-on:click="toggleDropdown">
      <div class="dropdown">
        <div :class=getDropdownClass()>
          <div v-if="allOptionsOriginal && allOptionsOriginal.length > 10">
            <img role="img" src="../../../../public/search.svg" />
            <input
              :name=name
              class="select-search-input"
              placeholderText="Search Options"
              autocomplete="off"
              v-model="localSearchValue"
              >
          </div>
          <ul>
            <li
              v-for="option in filteredOptionsToShow"
              :class="['dropdown-item', {'sidebar-select-dropdown-textcolor': sidebarSelect,
               'single-select-li': !isMultiSelect
              }]"
              v-on:click="handleSelect($event, option)"
              :data-key="option.Key"
              :data-value="option.Value"
              v-bind:key="option.Key"
            >
              <input
                v-if="isMultiSelect"
                type="checkbox"
                :checked="isOptionSelected(option.Key)"
              />
              <span class="value">
                {{ sidebarSelect? option.Value : $t(option.Value) }}
              </span>
            </li>
          </ul>
          <ul v-if="isMultiSelect & filteredOptionsToShow.length > 0" class="dropdown-item">
            <hr class="line-break">
            <li
              :class="['dropdown-item', {'sidebar-select-dropdown-textcolor': sidebarSelect}, 'select-all-input']"
            >
              <div v-on:click.stop="handleSelectAll()" data-testid="select-all-input" >
                <input
                  type="checkbox"
                  :checked="selectAllOptions"
                >
                <span class="value"> {{ $t(`select_all`) }} </span>
              </div>
              <button class="error-message clear-selection-btn"
                :class="{
                  'show-clear-selection-btn': this.localValue.length > 0
                }"
                data-testid="clear-selection"
                v-on:click.stop="handleClearSelection()">
                <u>
                  Clear Selection
                </u>
              </button>
            </li>
          </ul>
        </div>
        <div v-if="displayError" id="selectError" class="error-container">
          <p class="error-message">
            {{ $t(errorMessage) }}
          </p>
        </div>
        <div v-if="hasInfo && !hasError" id="selectInfo1" class="text-info">{{ $t(infoMessage) }}</div>
      </div>
    </div>
     <div v-else class="flex1" >
         <label  :class="[ 'ew-field-label', 'text-align-right', 'option-label-weight', 'flex1',{'text-align-left': orientation === 'v'}]">{{ $t(displayValue) }}</label>
         <div v-if="hasInfo && !hasError" id="selectInfo2" class="text-info">{{ $t(infoMessage) }}</div>
      </div>
  </div>
</template>

<script>

import { SelectMixin } from '../mixins/Select.js'
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'

export default {
  mixins: [ComponentMixin, SelectMixin]
}

</script>

<style lang="scss" scoped>
.input-group-field {
  display: flex;
  width: 100%;
}

.select {
  background: var(--input-bg) url("../../../../public/select.svg") no-repeat 95% 1.15rem;
  cursor: default;
  width: calc(100% - 32px);
}

.select::placeholder {
  color: $placeholderText;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  font-size: 14px;
}

.select:disabled {
  background: var(--input-disabled-bg);
  border: none;
}

.select:focus-visible {
  outline: none;
}

.error-container{
  margin-top: 2px;
}

.error-message{
  margin: 0px;
  color: $error;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}

.select.error{
  border: 1px solid $error !important;
  color: $error;
}

.label.error span {
  color: $error;
}

.dropdown {
  position: relative;
}

.dropdown img {
    position: absolute;
    left: 20px;
    pointer-events: none;
    top: 21px;
    width: 18px;
    height: 18px;
}

.dropdown-item {
  color: $label
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background-color-primary-light-dark);
  float: left;
  z-index: 1000;
  width: 100%;
  margin: 0;
  padding-inline: 0;
  margin-top: 2px;
  border-radius: 8px;
  box-shadow: 0 4px 4px var(--very-light-shadow);
}

.dropdown-menu ul {
  list-style: none;
  margin-left: 0;
  padding-inline-start: 0;
  max-height: 176px;
  overflow-y: auto;
}

.dropdown-menu li.hidden {
  min-height: 30px;
}

.dropdown-menu li {
  padding-left: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  line-height: 26px;
  font-size: 14px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 500;
  color: var(--text-label-color);
  cursor: pointer;
}

.dropdown-menu li:first-child:hover {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown-menu li:last-child:hover {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.dropdown-menu li:hover {
  background-color: var(--background-bg-color);
}

.dropdown-menu .single-select-li:hover:last-child {
  border-radius: 0 0 8px 8px;
}

.dropdown-menu .single-select-li:hover:first-child {
  border-radius: 8px 8px 0 0;
}

.select-all-input:hover {
  border-radius: 0 0 8px 8px !important;
}

.dropdown-menu li span.value {
  margin-left: 8px;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: var(--background-color-primary);
}

::-webkit-scrollbar-track {
  background: var(--background-color-primary);
}

::-webkit-scrollbar-thumb {
  background: $line;
}

.show {
  display: block;
}

input[type="checkbox"] {
  width: 18px;
  height: 18px;
  appearance: none;
  border: 2px solid $label;
  border-radius: 2px;
  margin: 1px 0 0;
  position: relative;
  top: 4px;
}

input[type="checkbox"]:checked {
  border-color: var(--primary-theme);
  background: var(--primary-theme) url('../../../../public/check.svg') no-repeat center;
}

.select-search-input {
  border: none;
  margin: 10px 10px 10px 10px;
  border-radius: 8px;
  height: 40px;
  padding-left: 36px;
  width: calc(92% - 36px);
  background-color: var(--background-bg-color);
  color: var(--text-primary-color);
}
.sidebar-select{
  border-color: var(--sidebar-language-selector);
  background: var(--sidebar-language-selector) url("../../../../public/select.svg") no-repeat 95% 0.65rem;
  color: #FFFFFF !important;
  font-size: 14px !important;
  height: 32px;
  width: 100% !important;
  position: relative;
  top: 20px;
}
.sidebar-select input {
  width: calc(92% - 36px);
}
.sidebar-select-dropdown {
  border-radius: 8px;
  background: var(--sidebar-language-selector) !important;
  color: #FFFFFF !important;
  position: relative;
  top: 20px;
}
.sidebar-select-dropdown-textcolor {
  background: var(--sidebar-language-selector) !important;
  color: #FFFFFF !important;
}
.line-break {
  border: 1px solid #A0A3BD26
}

.select-all-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clear-selection-btn {
  margin-top: 2px;
  padding-right: 16px;
  visibility: hidden;
}
.show-clear-selection-btn {
  visibility: visible;
}
</style>
