import axios from 'axios'
import queryString from 'query-string'
import { memoizedRefreshToken } from '../store/refreshToken'
import { isNotNullNorEmpty } from '../utils/Objects'
import { defaultToast, ToastType } from '../../../utils/toastUtils'

export const registryErrorCallback = e => {
  if (e !== undefined && e.response !== undefined && e.response.data !== undefined && e.response.data.message) {
    defaultToast(ToastType.Error, e.response.data.message)
  } else {
    defaultToast(ToastType.Error, 'Something went wrong. Please try after some time.')
  }
}

const registryServerInstance = axios.create({
  baseURL: process.env.VUE_APP_REGISTRY_URL
})

registryServerInstance.defaults.withCredentials = true

registryServerInstance.interceptors.request.use(
  async (config) => {
    var token = localStorage.getItem('auth-token')
    if (isNotNullNorEmpty(token)) {
      config.headers = {
        authorization: `Bearer ${token}`
      }
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

registryServerInstance.interceptors.response.use(
  response => {
    return response
  },
  async (error) => {
    const originalConfig = error.config
    const code = parseInt(error.response && error.response.status)
    if (code === 401 && !originalConfig._retry) {
      originalConfig._retry = true
      const token = await memoizedRefreshToken()
      registryServerInstance.defaults.headers.Authorization = `Bearer ${token}`
      return registryServerInstance(originalConfig)
    }
    return Promise.reject(error)
  }
)

const defaultClientHeader = {
  Authorization: 'jwt ' + '',
  'X-Client-Id': process.env.VUE_APP_CLIENT_ID
}

class RegistryApiClient {
  axiosInstance = null
  defaultHeader = null
  constructor (axiosIns, defaultH) {
    this.axiosInstance = axiosIns
    this.defaultHeader = defaultH
  }

  getCancelToken () {
    return axios.CancelToken
  }

  async post (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .post(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) {
          errCallback(thrown)
        }
      })
    if (response && response !== undefined && response.data !== undefined) {
      return response.data
    }
  }

  async patch (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .patch(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) {
          if (!errCallback) {
            errCallback = registryErrorCallback
          }

          errCallback(thrown)
        }
      })
    if (response) return response.data
  }

  async put (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .put(path, data, { cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) {
          if (!errCallback) {
            errCallback = registryErrorCallback
          }
          errCallback(thrown)
        }
      })
    if (response) return response.data
  }

  async delete (path, data, errCallback = () => {}, cancelToken = null) {
    const response = await this.axiosInstance
      .delete(path, { data, cancelToken, headers: this.defaultHeader })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) {
          if (!errCallback) {
            errCallback = registryErrorCallback
          }
          errCallback(thrown)
        }
      })
    if (response) return response.data
  }

  async get (path, data, errCallback = () => {}, cancelToken = null) {
    if (isNotNullNorEmpty(data)) {
      const reqString = queryString.stringify(data, { arrayFormat: 'comma' })
      path = `${path}?${reqString}`
    }
    const response = await this.axiosInstance({
      url: path,
      method: 'get',
      cancelToken,
      headers: this.defaultHeader
    }).catch(function (thrown) {
      if (axios.isCancel(thrown)) {
        console.log('Request canceled', thrown.message)
      } else if (thrown.response.status === 429) {
        const err = new Error()
        err.code = 429
        err.name = 'Retry'
        err.Retry = thrown.response.headers.retryafter
        throw err
      } else {
        errCallback(thrown)
      }
    })
    if (response) return response.data
  }
}

export const RegistryServerClient = new RegistryApiClient(registryServerInstance, defaultClientHeader)
