<template>
  <div :class="['tabBlock', colorTheme]">
    <!-- SVG sprite start -->
    <svg hidden xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <symbol id="icon-home" viewBox="0 0 576 512"><path fill="currentColor" d="M280.37 148.26L96 300.11V464a16 16 0 0016 16l112.06-.29a16 16 0 0015.92-16V368a16 16 0 0116-16h64a16 16 0 0116 16v95.64a16 16 0 0016 16.05L464 480a16 16 0 0016-16V300L295.67 148.26a12.19 12.19 0 00-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 00-12-12h-56a12 12 0 00-12 12v72.61L318.47 43a48 48 0 00-61 0L4.34 251.47a12 12 0 00-1.6 16.9l25.5 31A12 12 0 0045.15 301l235.22-193.74a12.19 12.19 0 0115.3 0L530.9 301a12 12 0 0016.9-1.6l25.5-31a12 12 0 00-1.7-16.93z"/></symbol>
      <symbol id="icon-vue" viewBox="0 0 448 512"><path fill="currentColor" d="M356.9 64.3H280l-56 88.6-48-88.6H0L224 448 448 64.3h-91.1zm-301.2 32h53.8L224 294.5 338.4 96.3h53.8L224 384.5 55.7 96.3z"/></symbol>
      <symbol id="icon-nuxt" viewBox="0 0 24 24"><path d="M19.724 20.272l.023-.045a.74.74 0 00.053-.117l.002-.005a1.16 1.16 0 00.054-.607l.001.007a1.795 1.795 0 00-.235-.623l.005.008-5.317-9.353-.812-1.42-.807 1.42-5.314 9.353a1.85 1.85 0 00-.204.604l-.001.011a1.257 1.257 0 00.08.713l-.003-.008a.809.809 0 00.05.102l-.002-.004c.16.275.5.602 1.25.602h9.898c.157 0 .925-.032 1.28-.637zm-6.227-9.316l4.859 8.548H8.64zm10.249 7.934L16.73 6.53c-.072-.13-.477-.787-1.182-.787-.317 0-.772.135-1.142.785l-.907 1.59.807 1.42 1.25-2.212 6.941 12.18h-2.64a1.187 1.187 0 01-.058.608l.003-.008a.772.772 0 01-.057.126l.002-.004-.023.045c-.355.605-1.122.637-1.272.637h4.129c.152 0 .917-.032 1.272-.637.157-.275.27-.737-.107-1.382zM7.304 20.307a.989.989 0 01-.045-.092l-.002-.006a1.228 1.228 0 01-.084-.712l-.001.007H1.501L9.93 4.672l2.767 4.864.802-1.42-2.412-4.249c-.067-.122-.475-.777-1.177-.777-.317 0-.772.137-1.142.787L.23 18.889c-.072.13-.425.812-.075 1.417.16.275.5.602 1.25.602h7.151c-.745 0-1.09-.322-1.25-.602z"/></symbol>
    </svg>
    <!-- SVG sprite end -->

    <ul class="tabBlock-tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :aria-setsize="tabs.length"
        :aria-posinet="index + 1"
      >
        <router-link :id=getTabId(tab.tab_title)
         :to= tab.to_link
         tag="a"
         class="tabBlock-tab tab_title"
         active-class="active"
         :class="active_tab === index ? 'is-active' : ''"
         :aria-selected="active_tab === index"
         @click.native="changeTab(index)">
          {{$t((tab.tab_title).toLowerCase()) }}
        </router-link>
      </li>
    </ul>
    <div class="tabBlock-content">
      <router-view/>
    </div>
  </div>
</template>

colorTheme: light/dark.

<script>
export default {
  name: 'Tabs',
  props: {
    tabs: {
      type: Array
    },
    colorTheme: {
      type: String,
      default: 'dark'
    }
  },
  methods: {
    changeTab (tabIndexValue) {
      this.active_tab = tabIndexValue
    },
    getTabId (title) {
      const tabName = this.$t((title).toLowerCase())
      return tabName.replace(/ /g, '')
    }
  },
  computed: {
    active_tab () {
      if (this.$route.name !== null) {
        var route = this.$route.name
        return this.tabs.findIndex(i => (i.tab_title.toUpperCase() === route.toUpperCase() || i.alternate_title === route.toUpperCase()))
      } else {
        return -1
      }
    }
  }
}
</script>

<style lang="scss" scoped>
*,
::before,
::after {
  box-sizing: border-box;
}

.tab_title {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    // height: 54px;
}

.tabBlock svg {
  height: 1px;
}

$grey: #6E7191;
$border: #d8d8d8;

.tabBlock {
  &-tabs {
    list-style: none;
    margin: 0;
    padding: 0;

    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  &-tab {
    min-height: 54px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
    font-weight: 600;
    float: left;
    padding: 0.625rem 1.25rem;
    transition: 0.1s ease-in-out;
    text-decoration: none;

    box-shadow: 0px 3px 3px var(--very-light-shadow);

    &::before,
    &::after {
      content: "";
      display: block;
      height: 4px;
      position: absolute;
      transition: 0.1s ease-in-out;
    }

    &::after {
      left: -2px;
      right: -2px;
      top: -2px;
    }

    &::after {
      bottom: -2px;
      left: 0;
      right: 0;
    }

    @media screen and (min-width: 700px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    &.is-active {
      border-bottom: solid;
      border-width: 2px;
      z-index: 1;
    }

    svg {
      height: 1.2rem;
      width: 1.2rem;
      margin-right: 0.5rem;
      pointer-events: none;
      fill: currentcolor;
      margin-bottom: 8px;
    }

  }

  &-content {
    a {
      font-weight: 700;
      transition: color 200ms ease;
    }

    hr {
      // margin: 3rem 0;
      border: 1px solid transparent;
      border-radius: 50%;
      border-top-color: var(--primary-theme);
    }
  }
}

// light theme style =====================================================

.light {
  .tabBlock-tab {
    background: var(--background-color-primary-light-dark);
    border-color: $border;
    color: var(--tabs-text-color);

    &::after {
      background-color: $grey;
    }

    &::after {
      background-color: transparent;
    }

    &:hover,
    &:focus {
      color: var(--primary-color-text);
    }

    .is-active {
      color: var(--primary-theme);
      border-color: var(--primary-theme);
      &::after {
        background-color: var(--primary-theme);
       }
    }
  }
  .is-active {
    color: var(--primary-color-text);
    border-color: var(--primary-color-text);
    background: var(--highlight-color);
  }
}

// dark theme style =====================================================

.dark {
  .tabBlock-tab {
    background: var(--primary-theme);
    // border-color: $border;
    color: $white;

    &::after {
      background-color: $white;
    }

    &::after {
      background-color: transparent;
    }

    &:hover,
    &:focus {
      color: $white;
    }

    .is-active {
      color: $white;
      border-color: var(--primary-theme);
    }
  }
  .is-active {
    color: $white;
    border-color: $white;
  }
}

</style>
