<template>
  <div class="tasklist-container">
    <div class="tasklist-option-container" >
      <div class="task_list_option" v-for="taskList in taskListsDetails" v-bind:key="taskList.id"
          v-on:click="loadTaskList(taskList.description)">
        <div class="task_list_option_left text-center">
          <font-awesome-icon v-if="taskList.webIcon !== ''" :icon="icons[taskList.webIcon]" class="ufml-md fa-4x" />
        </div>

        <div class="task_list_option_right">
          <b class="task_list_option_title text-ibm-plex-sans">{{ $t(taskList.name) }}</b>
          <p class="task_list_option_desc text-ibm-plex-sans"> {{ $t(taskList.description) }}</p>
        </div>

        <div class="float_clear"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { FontAwsoneV5IconName } from '@/compatibilityHelpers/fontAwsomeCompatibility.js'
import { RouteConstants } from '../../../../../constants/index.js'

export default {
  name: 'TaskLists',
  components: {
  },
  data () {
    return {
      icons: {}
    }
  },
  computed: {
    ...mapState('Tasklist', [
      'taskListsDetails', 'taskListDescInputDataMap', 'taskListItemMappedByTasklistDesc'
    ])
  },
  methods: {
    ...mapActions('Tasklist', ['setInputData', 'setSelectedTasklistItem']),
    async loadTaskList (descKey) {
      if (this.patientsLoading) {
        return
      }

      this.setInputData(this.taskListDescInputDataMap[descKey])
      this.setSelectedTasklistItem(this.taskListItemMappedByTasklistDesc[descKey])
      this.$router.push(`Tasklists/${RouteConstants.TaskListDetails.path}/${descKey}`)
    }
  },
  mounted () {
    this.icons = FontAwsoneV5IconName
  }

}
</script>

<style lang="scss" scoped>
.task_list_option {
    background-color: var(--light-hover-color);
    padding: 10px 0px;
    cursor: pointer;
    margin-bottom: 20px;
    border-radius: 5px;
    border: 1px solid var(--input-border);
    color: var(--app-color);
}
.taskListLoading {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}

.task_list_option.no_margin_bottom {
    margin-bottom: 0px;
}

.task_list_option:hover {
    background-color: var(--task-list-hover);
}

.task_list_option_left {
    width: 100px;
    float: left;
}

.task_list_option_left i {
    font-size: 80px;
}

.task_list_option_right {
    width: calc(100% - 100px);
    float: right;
}

.float_clear {
    clear: both;
}

.task_list_option_title {
    font-size: 24px;
    margin-bottom: 10px;
    display: block;
}

.task_list_option_desc {
    margin-bottom: 0px;
}

.tasklist-container{
  padding: 0 1em;
}
* {
  margin: revert;
  box-sizing: unset;
}

</style>
