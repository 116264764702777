<template>
  <div id="tab_section">
    <v-expand-transition>
      <Tabs
        v-if="dataPresent"
        :tabs="tabs"
        colorTheme="light"
      />
    </v-expand-transition>
  </div>
</template>

<script>
import Tabs from '@/app/shared/components/Tabs.vue'
import { mapState, mapActions } from 'vuex'
import EventBus from '@/app/shared/event-bus.js'

export default {
  name: 'TassklistDetails',
  components: {
    Tabs
  },
  data: function () {
    return {
      tabs: [],
      dataPresent: false
    }
  },
  computed: {
    ...mapState('Tasklist', [
      'taskListsDetails', 'taskListDescInputDataMap', 'taskListItemMappedByTasklistDesc'
    ])
  },
  methods: {
    ...mapActions('Tasklist', ['getLoggedInUserHierarchyId', 'loadTaskListDetails', 'setInputData', 'setSelectedTasklistItem', 'loadTaskListDetailsForced']),
    getTabsDetails () {
      return this.taskListsDetails.map((val) => {
        return { tab_title: val.name.toLowerCase(), to_link: `/Dashboard/Tasklists/tasklistdetails/${val.description}`, alternate_title: val.description.toUpperCase() }
      })
    },
    populateTabs () {
      const tabItems = this.getTabsDetails()
      this.dataPresent = true
      this.tabs = tabItems
    },
    async loadAndPopulateTabs () {
      await this.getLoggedInUserHierarchyId()
      await this.loadTaskListDetails()
      const descKey = this.$route.name
      await this.setInputData(this.taskListDescInputDataMap[descKey])
      await this.setSelectedTasklistItem(this.taskListItemMappedByTasklistDesc[descKey])
      this.populateTabs()
    },
    async forceReloadTabs () {
      await this.loadTaskListDetailsForced()
      this.populateTabs()
    }
  },
  async mounted () {
    EventBus.$on('LANGUAGE_CHANGE', this.forceReloadTabs)
    if (this.taskListsDetails.length !== 0) {
      this.populateTabs()
    } else {
      await this.loadAndPopulateTabs()
    }
  }
}
</script>

<style scoped>
.left-container {
  display: flex;
  align-items: center;
  width: fit-content;
}

.tab-content > .tab-pane {
  display: none;
}
.show {
  display: block !important;
}
.actionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
